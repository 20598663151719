/* Add import statement for custom font here */

html
{
  font: 15px Arial, Sans-Serif;
  color: #000000;
}

h1, h2, h3
{
  line-height: 120%;
}
h1
{
  font-size: 30px;
}
h2
{
  font-size: 22px;
}
h3
{
  font-size: 18px;
}

p a
{
  color: inherit;
}

p
{
  margin-bottom: 20px;
  line-height: 160%;
}

sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

/* lists */
  ul.unordered
, ol.ordered
{
  margin: 0 0 20px;
  padding-bottom: 0;
  padding-top: 0;
  margin-left: 0;
  line-height: 160%;
}

/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
