@charset "UTF-8";
html {
  background-color: #ffffff;
}

html, body {
  margin: 0;
  padding: 0;
  line-height: 130%;
}

body {
  overflow-y: scroll;
  color: #404040;
  font-family: "PT Sans", sans-serif;
  font-size: 15px;
  line-height: 26px;
  font-style: normal;
  font-weight: normal;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent; /* For some Androids */
  background: #ffffff;
  background-size: cover !important;
}

p {
  padding-bottom: 5px;
}

.bodypart {
  width: 100%;
}

.bodypart > * {
  margin: 0 auto;
  width: 1080px;
}

/** Images */
img {
  border: none;
}

img.centeredimage {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img.leftimage {
  float: left;
  padding-right: 10px;
}

img.rightimage {
  float: right;
  padding-left: 10px;
}

a[href] {
  color: #4a596c;
  text-decoration: none;
  transition: color 0.2s;
}

#bodycontainer a[href]:hover {
  text-decoration: underline;
}

a[href]:focus {
  outline: 0;
}

a[href]:active {
  outline: 0;
}

/** Text layout */
hr {
  height: 1px;
  color: #4a596c;
  background-color: #ffffff;
  margin: 20px 0;
  border: #778527 1px dashed;
  border-style: none none dashed;
}

h1 {
  font-family: "PT Sans", sans-serif;
  font-size: 28px;
  color: #404040;
  margin: 0;
  font-weight: 300;
  line-height: 150%;
}

h2 {
  color: #404040;
  font-size: 16px;
  line-height: 20px;
  margin: 10px 0 0 0;
  font-weight: normal;
  font-family: "PT Sans", sans-serif;
}

h3 {
  color: #404040;
  font-size: 110%;
  margin: 16px 0 0 0;
}

h4 {
  color: #404040;
  margin: 0;
}

p.center {
  text-align: center;
}

table td {
  text-align: left;
  padding: 2px 5px;
  vertical-align: top;
}

#slidebackground {
  background: #f8f8f8;
}

#pagetop {
  position: relative;
  top: 1px;
}

#mainmenuholder {
  height: 50px;
}

#mainmenu {
  position: relative;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  background: #ffffff;
  text-align: center;
  width: 100%;
  height: 100%;
}

#mainmenu > li {
  color: #777777;
  padding: 15px;
  display: inline-block;
  position: relative;
}

#mainmenu > li > a {
  font: 20px "PT Sans", sans-serif;
  padding: 0px 20px;
  color: #404040;
  font-weight: normal;
}

#mainmenu a.menulink,
#mainmenu a.menulink.selected {
  text-decoration: none;
  color: #747474;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  padding: 0 10px;
}

#logoholder {
  height: 180px;
  background: #f0f0f0;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  z-index: 1;
}

#logo {
  position: absolute;
  top: 10px;
  left: 60px;
}

#containers {
  margin: 20px 0;
  height: 35px;
  width: 100%;
}

#contacttop {
  box-sizing: border-box;
  padding: 5px 0;
  background: #dddddd;
}

#contactcontent {
  font-family: "PT Sans", sans-serif;
  line-height: 1.8em;
  font-size: 13px;
  color: #929ba7;
  width: 1050px;
}

#credits {
  box-sizing: border-box;
  padding: 16px 30px;
  background: #ffffff;
}

#creditsfooter {
  box-sizing: border-box;
  width: 1050px;
  font-family: "PT Sans", sans-serif;
  line-height: 1.8em;
  color: #404040;
  text-align: left;
}

ul.wh-menu-detached {
  background-color: #f1f1f1;
  text-decoration: none;
  color: #4a596c !important;
  border-radius: 4px;
  box-shadow: 1px 2px 2px 0 #666666;
  padding: 20px;
  list-style-type: none;
  overflow: hidden;
}

ul.wh-menu-detached li {
  position: relative;
}

ul.wh-menu-detached li,
ul.wh-menu-detached a {
  color: #777777 !important;
  padding: 4px;
  font-size: 14px;
  font-family: "PT Sans", sans-serif;
}

ul.wh-menu-detached li a:hover {
  color: #444444 !important;
  text-decoration: none;
  cursor: pointer;
}

ul.wh-menu-detached li ul {
  position: absolute;
  left: 100%;
  margin-left: 25px;
  top: -15px;
}

#bodycontainer {
  box-sizing: border-box;
  width: 1080px;
  margin: 0 auto;
  background: #ffffff;
}

#bodycontainer table {
  border-collapse: collapse;
  border: none;
}

table p {
  margin: 0;
}

#contentcolumn {
  width: 1080px;
  padding: 30px;
  vertical-align: top;
}

#righthr {
  margin: 0 auto;
  display: block;
  padding-top: 10px;
}

#time {
  color: #9e9e9e;
  font-size: 18px;
  font-weight: 300;
  padding: 5px 0;
  line-height: 20px;
}

#bodycontainer .pagebody.nosubmenu {
  width: 960px;
}

#slideshow {
  width: 1080px;
  height: 336px;
}

/* hoofdmenu
*/
ul.wh-menubar > li,
ul.wh-menubar > li.current {
  color: #777777;
  padding: 15px;
}

ul.wh-menubar > li:hover,
ul.wh-menubar > li.current {
  color: #4a596c;
}

ul.wh-menubar > li.hassubmenu:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -4px;
  right: 8px;
  width: 7px;
  height: 7px;
  display: block;
  background: url("./web/img/menudown.png") no-repeat;
}

ul.wh-menubar > li > a {
  font: 20px "PT Sans", sans-serif;
  padding: 0px 20px;
  color: #404040;
  font-weight: normal;
}

ul.wh-menubar > li > a:hover {
  color: #000000;
}

ul.wh-menubar > li.selected {
  cursor: pointer;
  color: #bf2433;
}

ul.wh-menulist {
  background: #f7f7f7;
  border-radius: 4px;
  box-shadow: 1px 2px 2px 0 #666666;
  padding: 4px 10px 8px 10px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  overflow: hidden;
}

ul.wh-menulist.level-1 {
  margin-top: 6px;
}

ul.wh-menulist.level-2 {
  margin-left: 12px;
}

ul.wh-menulist li {
  padding: 5px 65px 5px 2px;
  font: 14px Core Sans;
  color: #777777;
}

ul.wh-menulist li.selected {
  color: #444444;
}

ul.wh-menulist li.hassubmenu:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -4px;
  right: 0px;
  width: 7px;
  height: 7px;
  display: block;
  background: url("./web/img/menuright.png") no-repeat;
}